import React, { useState } from 'react'
import './Labs.css'
import LABSJSON from '../../../Labs.json'
import Highlighter from "react-highlight-words";

export default function Labs(props) {
    const [selectedTab, setSelectedTab] = useState('Serum')
    const [searchValue, setSearchValue] = useState('')
    const [displaySIReference, setDisplaySIReference] = useState(false)

    return (
        <div style={{overflowY: 'auto'}}>
            <div className='labsHeaderWrapper'>
                <input className='labsSearch' placeholder='Search' onChange={(e) => setSearchValue(e.target.value)} />
                <div className='labsHeaderCheckboxWrapper'>
                    <input type="checkbox" checked={displaySIReference} onChange={() => setDisplaySIReference(!displaySIReference)}/>
                    <label>SI Reference Intervals</label>
                </div>
            </div>
            <div className='labsBodyWrapper'>
                <div className='labsTabsWrapper'>
                    <button onClick={() => setSelectedTab('Serum')} className={`labsTab ${selectedTab === 'Serum' ? 'labsTabSelected' : ''}`}>Serum</button>
                    <button onClick={() => setSelectedTab('Cerebrospinal')} className={`labsTab ${selectedTab === 'Cerebrospinal' ? 'labsTabSelected' : ''}`}>Cerebrospinal</button>
                    <button onClick={() => setSelectedTab('Blood')} className={`labsTab ${selectedTab === 'Blood' ? 'labsTabSelected' : ''}`}>Blood</button>
                    <button onClick={() => setSelectedTab('Urine and BMI')} className={`labsTab ${selectedTab === 'Urine and BMI' ? 'labsTabSelected' : ''}`}>Urine and BMI</button>
                </div>
                <div className='labsBody'>
                    {LABSJSON.map((labValue, labIndex) => (
                        labValue.Tab === selectedTab &&
                            <div className='labsValueWrapper' key={`Labs value wrapper ${labValue.Tab} - ${labIndex}`}>
                                {labValue.Title &&
                                    <div className='labsValueContainer' style={{gridTemplateColumns: displaySIReference ? '1fr 1fr 1fr' : '1fr 1fr'}}>
                                        <Highlighter
                                            className="labsHeader"
                                            searchWords={[searchValue]}
                                            autoEscape={true}
                                            textToHighlight={labValue.Title}
                                        />
                                        {(labValue.Title === 'Serum' || labValue.Title === 'Cerebrospinal Fluid' || labValue.Title === 'Hematologic' || labValue.Title === 'Urine') && <p className='labsHeader' style={{paddingLeft: 0}}>Reference Range</p>}
                                        {((labValue.Title === 'Serum' || labValue.Title === 'Cerebrospinal Fluid' || labValue.Title === 'Hematologic' || labValue.Title === 'Urine') && displaySIReference) && <p className='labsHeader' style={{paddingLeft: 0}}>SI Reference</p>}
                                    </div>
                                }
                                {labValue.Values.map((value, index) => (
                                    <div className='labsValueContainer' key={`${labValue.Tab} item ${index}`} style={{gridTemplateColumns: displaySIReference ? '1fr 1fr 1fr' : '1fr 1fr'}}>
                                        <Highlighter
                                            className={`labsName ${labValue.Title ? 'labsNameExtraPadding' : ''}`}
                                            searchWords={[searchValue]}
                                            autoEscape={true}
                                            textToHighlight={value.LabName}
                                        />
                                        <Highlighter
                                            className='labsValue'
                                            searchWords={[searchValue]}
                                            autoEscape={true}
                                            textToHighlight={value.LabValue}
                                        />
                                        {displaySIReference && value.SILabValue &&
                                            <Highlighter
                                                className='labsValue'
                                                searchWords={[searchValue]}
                                                autoEscape={true}
                                                textToHighlight={value.SILabValue}
                                            />
                                        }
                                    </div>
                                ))}
                            </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
