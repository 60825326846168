import React from 'react';
import pauseIcon from '../../assets/pause-icon.png';
import stopIcon from '../../assets/stop-icon.png';

function TestFooter(props) {
  return (
    <footer className={`test-footer-wrapper ${props.isDarkMode ? "dark-footer" : ''}`}>
      <div className="item-info">
        <p>Block Time Remaining: {props.blockTimeRemaining}</p>
        <p>Day Time Remaining: {props.dayTimeRemaining}</p>
      </div>
      <div className="tools">
        <button onClick={() => props.pauseBlock()} className='footer-button tools-button'>
          <img src={pauseIcon} alt="Pause icon" className='tool-image' />
          <p>Pause Block</p>
        </button>
        <button onClick={() => props.endBlock()} className='footer-button tools-button'>
          <img src={stopIcon} alt="Stop icon" className='tool-image' />
          <p>End Block</p>
        </button>
      </div>
    </footer>
  )
}

export default TestFooter;