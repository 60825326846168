import React, { Component } from 'react'
import './Calculator.css'

export default class Calculator extends Component {
    constructor() {
        super()
        this.state = {
            calculatorValue: 0,
            adding: false,
            subtracting: false,
            multiplying: false,
            dividing: false,
            memory: 0,
            memoryValue: 0,
            decimal: false,
        }
    }

    handleCalculatorClick = (buttonSelected, buttonColor) => {
        console.log("Button selected: ", buttonSelected)

        const handleCalculatorNumber = (number, calculatorValue = this.state.calculatorValue) => {
            if (calculatorValue === 0) {
                this.setState({
                    calculatorValue: number
                })
            } else {
                this.setState({
                    calculatorValue: Number(calculatorValue + `${number}`)
                })
            }
        }

        let calculatorValueString = this.state.calculatorValue.toString()

        let calculatorValue = this.state.calculatorValue

        if (calculatorValueString[calculatorValueString.length - 1] === '.') {
            if (buttonColor !== 'gray') {
                calculatorValue = Number(calculatorValue)
            }
        }

        switch(buttonSelected) {
            case '0':
                handleCalculatorNumber(0, calculatorValue)
                break;
            case '1':
                handleCalculatorNumber(1, calculatorValue)
                break;
            case '2':
                handleCalculatorNumber(2, calculatorValue)
                break;
            case '3':
                handleCalculatorNumber(3, calculatorValue)
                break;
            case '4':
                handleCalculatorNumber(4, calculatorValue)
                break;
            case '5':
                handleCalculatorNumber(5, calculatorValue)
                break;
            case '6':
                handleCalculatorNumber(6, calculatorValue)
                break;
            case '7':
                handleCalculatorNumber(7, calculatorValue)
                break;
            case '8':
                handleCalculatorNumber(8, calculatorValue)
                break;
            case '9':
                handleCalculatorNumber(9, calculatorValue)
                break;
            case 'C':
                this.setState({ calculatorValue: 0 })
                break;
            case '+/-':
                this.setState({ calculatorValue: this.state.calculatorValue * -1 })
                break;
            case 'sqrt(x)':
                this.setState({ calculatorValue: parseFloat(Math.sqrt(this.state.calculatorValue).toFixed(12)) })
                break;
            case '1/x':
                this.setState({ calculatorValue: (1 / this.state.calculatorValue).toFixed(12) })
                break;
            case '÷':
                this.setState({ dividing: true, adding: false, subtracting: false, multiplying: false, memory: this.state.calculatorValue, calculatorValue: 0 })
                break;
            case 'x':
                this.setState({ multiplying: true, adding: false, subtracting: false, dividing: false, memory: this.state.calculatorValue, calculatorValue: 0 })
                break;
            case '-':
                this.setState({ subtracting: true, adding: false, multiplying: false, dividing: false, memory: this.state.calculatorValue, calculatorValue: 0 })
                break;
            case '+':
                this.setState({ adding: true, subtracting: false, multiplying: false, dividing: false, memory: this.state.calculatorValue, calculatorValue: 0 })
                break;
            case '.':
                if (this.state.calculatorValue.toString().includes('.')) {
                    this.setState({ calculatorValue: Number(this.state.calculatorValue) })
                } else {
                    this.setState({ calculatorValue: this.state.calculatorValue + '.', decimal: true })
                }
                break;
            case '=':
                if (this.state.adding) {
                    console.log(this.state.memory, this.state.calculatorValue)
                    this.setState({ calculatorValue: this.state.memory + this.state.calculatorValue, adding: false, memory: 0 })
                } else if (this.state.subtracting) {
                    this.setState({ calculatorValue: this.state.memory - this.state.calculatorValue, subtracting: false, memory: 0 })
                } else if (this.state.multiplying) {
                    this.setState({ calculatorValue: parseFloat((this.state.memory * this.state.calculatorValue).toFixed(12)), multiplying: false, memory: 0 })
                } else if (this.state.dividing) {
                    this.setState({ calculatorValue: parseFloat((this.state.memory / this.state.calculatorValue).toFixed(12)), dividing: false, memory: 0 })
                }
                break;
            case 'M+':
                this.setState({ memoryValue: this.state.memoryValue + this.state.calculatorValue })
                break;
            case 'MR':
                this.setState({ calculatorValue: this.state.memoryValue })
                break;
            case 'MC':
                this.setState({ memoryValue: 0 })
                break;
            default:
                console.log(`Unhandled Button: ${buttonSelected}`)
                break;
        }

    }

    render() {
        return (
            <div>
                <div className='calculator-wrapper'>
                    <div className='calculator-screen-container'>
                        <p className='calculator-screen-text'>
                            {this.state.calculatorValue}
                        </p>
                        {this.state.memoryValue !== 0 &&
                            <p className='calculator-memory-indicator'>
                                M
                            </p>
                        }
                    </div>
                    <div className='calculator-buttons-container'>
                        <button className='calculator-button calculator-purple' onClick={() => this.handleCalculatorClick('M+')}>M+</button>
                        <button className='calculator-button calculator-purple' onClick={() => this.handleCalculatorClick('MR')}>MR</button>
                        <button className='calculator-button calculator-purple' onClick={() => this.handleCalculatorClick('MC')}>MC</button>
                        <button className='calculator-button calculator-yellow' onClick={() => this.handleCalculatorClick('C')}>C</button>
                        <button className='calculator-button calculator-blue' onClick={() => this.handleCalculatorClick('+/-')}>+/-</button>
                        <button className='calculator-button calculator-blue' onClick={() => this.handleCalculatorClick('sqrt(x)')}>sqrt(x)</button>
                        <button className='calculator-button calculator-blue' onClick={() => this.handleCalculatorClick('1/x')}>1/x</button>
                        <button className='calculator-button calculator-blue' onClick={() => this.handleCalculatorClick('÷')}>÷</button>
                        <button className='calculator-button' onClick={() => this.handleCalculatorClick('7', 'gray')}>7</button>
                        <button className='calculator-button' onClick={() => this.handleCalculatorClick('8', 'gray')}>8</button>
                        <button className='calculator-button' onClick={() => this.handleCalculatorClick('9', 'gray')}>9</button>
                        <button className='calculator-button calculator-blue' onClick={() => this.handleCalculatorClick('x')}>x</button>
                        <button className='calculator-button' onClick={() => this.handleCalculatorClick('4', 'gray')}>4</button>
                        <button className='calculator-button' onClick={() => this.handleCalculatorClick('5', 'gray')}>5</button>
                        <button className='calculator-button' onClick={() => this.handleCalculatorClick('6', 'gray')}>6</button>
                        <button className='calculator-button calculator-blue' onClick={() => this.handleCalculatorClick('-')}>-</button>
                        <button className='calculator-button' onClick={() => this.handleCalculatorClick('1', 'gray')}>1</button>
                        <button className='calculator-button' onClick={() => this.handleCalculatorClick('2', 'gray')}>2</button>
                        <button className='calculator-button' onClick={() => this.handleCalculatorClick('3', 'gray')}>3</button>
                        <button className='calculator-button calculator-blue' onClick={() => this.handleCalculatorClick('+')}>+</button>
                        <div className='calculator-bottom-wrapper'>
                            <button className='calculator-button' onClick={() => this.handleCalculatorClick('0', 'gray')}>0</button>
                            <button className='calculator-button' onClick={() => this.handleCalculatorClick('.')}>.</button>
                            <button className='calculator-button calculator-yellow' onClick={() => this.handleCalculatorClick('=')}>=</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
