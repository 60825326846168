import React from 'react';
import MDSpinner from 'react-md-spinner';
import Calculator from './Calculator/Calculator';
import Labs from './Labs/Labs';
import DoctorImage from '../../assets/Doctor.png';

function Question(props) {
  const { isDarkMode, fontSize, selectedAnswers, currentQuestionData, currentAnswerData } = props;

  // Logic for rendering the answer options //
  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  let answerList;
  if (currentAnswerData) {
    answerList = currentAnswerData.map((answerOption, index) => {
      let inputType;
      // If multiple choice or not //
      if (currentQuestionData.MAXACCEPTEDANSWERS > 1) {
        inputType = "checkbox";
      } else {
        inputType = "radio";
      }
      return (
        <div key={answerOption.ANSWER}>
          <input
            type={inputType} 
            checked={selectedAnswers.includes(`${answerOption.ANSWERID}`)}
            onChange={props.handleAnswerChange}
            id={letters[index]} 
            name="answers" 
            value={answerOption.ANSWERID}
          />
          <label style={{width: '100%'}} htmlFor={letters[index]}>{letters[index]}. <span>{answerOption.ANSWER}</span></label>
        </div>
      );
    });
  }

  return (
    <div className={isDarkMode ? "question-container bg-dark text-white" : "question-container"} style={{ fontSize: fontSize.style }}>
      <div className='testWrapper'>  
          <React.Fragment>
              <p className='question'>
                {currentQuestionData.QUESTION}
              </p>
              {currentQuestionData.ADDITIONALIMAGE !== "" &&
                <img className='question-image' src={props.route + currentQuestionData.ADDITIONALIMAGE}/>
              }
              <div className={`answers ${isDarkMode ? 'border-white' : ''} ${props.isCurrentQuestionCompleted ? 'answerListDisabled' : ''}`}>
                {answerList}
              </div>
              {props.isCurrentQuestionCompleted &&
                <p className='questionCompletedText'>Note: You can't change your answers for previously submitted questions.</p>
              }
              <div className='button-container'>
                {props.isLoading ?
                  <MDSpinner
                    size={30}
                    singleColor={'#004976'}
                  />
                :
                  <button
                    onClick={() => props.nextQuestion()}
                    style={{ fontSize: fontSize.style }}
                  >{props.isSelectedQuestionLastQuestion ? 'Submit Test' : 'Proceed to next item'}</button>
                }
              </div>
          </React.Fragment>
      </div>
      <div className='desktopToolsWrapper'>
        <div className='labsWrapper' style={{width: props.displayLabs ? 500 : 0}}>
          <Labs />
        </div>
        <div className='calculator-container' style={{width: props.displayCalculator ? 275 : 0}}>
          <Calculator />
        </div>
      </div>
    </div>
  );
}

export default Question;