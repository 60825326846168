import React, { Component } from 'react'
import Chart from 'chart.js/auto'

export default class DonutChart extends Component {
    constructor() {
        super()
        this.state = {
            donutChart: null
        }
    }

    chartRef = React.createRef()

    componentDidMount() {
        this.buildChart()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.options.cutout !== this.props.options.cutout) {
            let donutChart = this.state.donutChart
            donutChart.options = this.props.options
            donutChart.update(0)
            this.setState({donutChart})
        }
        // if (prevProps.data !== this.props.data) {
        //     let donutChart = this.state.donutChart
        //     donutChart.data = this.props.data
        //     donutChart.update(0)
        //     this.setState({donutChart})
        // }
    }

    componentWillUnmount() {
        let myDonutChart = this.state.donutChart
        myDonutChart.destroy()
        this.setState({myDonutChart})
    }

    buildChart = () => {
        const myChartRef = this.chartRef.current.getContext("2d")

        let myDonutChart = this.state.donutChart

        if (typeof myDonutChart !== "undefined" && myDonutChart !== null) myDonutChart.destroy();

        myDonutChart = new Chart(myChartRef, {
            type: 'doughnut',
            data: this.props.data,
            options: this.props.options
        })
        this.setState({donutChart: myDonutChart})
    }

    render() {
        return (
            <div className={'donutChartContainer'}>
                <canvas id='myChart' ref={this.chartRef}/>
            </div>
        )
    }
}
