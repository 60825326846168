import React, { Component } from 'react'
import './IncompleteBlockPopup.css'
import { motion } from 'framer-motion/dist/framer-motion'
import MDSpinner from 'react-md-spinner'
import ConvertMountainTimeToLocal from '../../../Functions/ConvertMountainTimeToLocal'

export default class IncompleteBlockPopup extends Component {
    constructor() {
        super()
        this.state = {
            optionSelected: -1,
        }
    }

    formatTime = (rawTime) => {
        let date = new Date(ConvertMountainTimeToLocal(rawTime, this.props.userData.TimeZone))
        const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

        let month = MONTHS[date.getMonth()]
        let day = date.getDate()
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let dayPeriod = 'AM'

        if (hours > 12) {
            hours -= 12
            dayPeriod = 'PM'
        } else if (hours === 12) {
            dayPeriod = 'PM'
        } else if (hours === 0) {
            hours = 12
        }

        let suffix = ""

        if (day % 10 === 1 && day !== 11)
            suffix = 'st'
        else if (day % 10 === 2 && day !== 12)
            suffix = 'nd'
        else if (day % 10 === 3 && day !== 13)
            suffix = 'rd'
        else
            suffix = 'th'
    
        return `${month} ${day}${suffix}, ${year}, ${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${dayPeriod}`
    }

    render() {
        return (
            <div className='incompleteBlockPopupPageContainer' key='askjdhfoisdf'>
                <motion.div
                    key='incompleteBlockPopupBackdrop'
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.2}}
                    className='incompleteBlockPopupBackdrop'
                    onClick={this.props.closePopup}
                />
                <motion.div
                    className='incompleteBlockPopupContainer'
                    initial={{y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
                    animate={{y: 0, opacity: 1, translateX: '-50%', translateY: '-50%', scale: 1}}
                    exit={{y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
                    transition={{duration: 0.2}}
                    key="incompleteBlockPopupContainer"
                >
                    <button className='popupCloseButton' onClick={this.props.closePopup}/>
                    <h3 className='incompleteBlockPopupHeader'>Continue Previous Attempt?</h3>
                    <p className='incompleteBlockPopupText'>If you have left a test without completing it in the past, you can continue it here.</p>
                    <div className='incompleteBlockPopupOptionsWrapper'>
                        {this.props.incompleteBlockList.map((value, index) => (
                            <div key={`${value.TimeAttempted} - ${index}`} className={`incompleteBlockPopupOptionContainer${this.state.optionSelected === index ? ' incompleteBlockPopupOptionSelected' : ''}`} onClick={() => this.setState({optionSelected: index})}>
                                {value.CustomerLabel === "" ?
                                    <p className='incompleteBlockPopupOptionText'>{this.formatTime(value.TimeAttempted)}</p>
                                :
                                    <p className='incompleteBlockPopupOptionText'>{value.CustomerLabel}</p>
                                }
                            </div>
                        ))}
                    </div>
                    <div className='incompleteBlockPopupButtonWrapper'>
                        <button className='incompleteBlockPopupButton secondaryButton' onClick={this.props.closePopup}>Cancel</button>
                        {/* {true ? */}
                        {this.props.continueCaseLoading ?
                            <div className='incompleteBlockPopupLoadingWrapper'>
                                <MDSpinner
                                    size={30}
                                    singleColor={this.props.primaryColor}
                                />
                            </div>
                        :
                            <button className={`incompleteBlockPopupButton primaryButton${this.state.optionSelected === -1 ? ' incompleteBlockPopupButtonDisabled' : ' incompleteBlockPopupButtonActive'}`} onClick={() => this.props.confirmIncompleteBlockStart(this.state.optionSelected, this.props.incompleteBlockList)}>Continue Test</button>
                        }
                    </div>
                </motion.div>
            </div>
        )
    }
}
