import React from 'react'
import './SuccessPopup.css'
import { motion } from 'framer-motion/dist/framer-motion'

export default function SuccessPopup(props) {
  return (
    <motion.div className='errorPopupContainer'>
        <button className='errorPopupCloseButton successPopupCloseButton' onClick={props.closePopup}/>
        <div className='errorPopupTextWrapper'>
            <h2 className='errorPopupTitle successPopupTitle'>{props.popupTitle}</h2>
            <p className='errorPopupText'>{props.popupText}</p>
        </div>
    </motion.div>
  )
}
