import React, { Component } from 'react'
import './ViewCompletionsPopup.css'
import { motion } from 'framer-motion/dist/framer-motion'
import MDSpinner from 'react-md-spinner'
import ConvertMountainTimeToLocal from '../../../Functions/ConvertMountainTimeToLocal'

export default class ViewCompletionsPopup extends Component {
    constructor() {
        super()
        this.state = {
            fetchOutstanding: false
        }
    }

    confirmNewCompletion = async () => {
        let id = this.props.previousCompletions[this.props.previousCompletionSelected].CustomerGradeId
        this.setState({fetchOutstanding: true})
        await this.props.confirm(id)
        this.props.closePopup()
    }

    formatDate = (rawDate) => {

        let date = new Date(ConvertMountainTimeToLocal(rawDate, this.props.timeZone))
        let month = date.getMonth() + 1
        let day = date.getDate()
        let year = date.getFullYear()
        let hour = date.getHours()
        let minute = date.getMinutes()
        let ampm = hour >= 12 ? 'PM' : 'AM'
        hour = hour % 12
        hour = hour ? hour : 12
        minute = minute < 10 ? '0' + minute : minute
        let strTime = hour + ':' + minute + ' ' + ampm
        return month + '/' + day + '/' + year + ' ' + strTime
    }

  render() {
    return (
      <div className='viewCompletionsPopupPageWrapper'>
        <motion.div
            className='viewCompletionsPopupBackdrop'
            onClick={this.props.closePopup}
            key='viewCompletionsPopupBackdrop'
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.2}}
        />
        <motion.div
            className='viewCompletionsPopupContainer'
            initial={{y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
            animate={{y: 0, opacity: 1, translateX: '-50%', translateY: '-50%', scale: 1}}
            exit={{y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
            transition={{duration: 0.2}}
            key="viewCompletionsPopupContainer"
        >
            <button className='popupCloseButton' onClick={this.props.closePopup}/>
            <h2 className='viewCompletionsPopupTitle'>Previous Completions</h2>
            {/* <h4 className='viewCompletionsPopupSubtitle'>Score on First Attempt: {this.props.previousCompletions[0].FirstAttemptGrade}%</h4> */}
            <h4 className='viewCompletionsPopupSubtitle'>Select a previous attempt below</h4>
            <div className='viewCompletionsPopupOptionsContainer'>
                {this.props.previousCompletions.map((completion, index) => (
                    <div className={`viewCompletionsPopupOption ${this.props.previousCompletionSelected === index ? 'viewCompletionsPopupOptionSelected' : ''}`} key={completion.TimeAttempted} onClick={() => this.props.updatePreviousCompletionSelected(index)}>
                        <div className='viewCompletionsPopupOptionGradeContainer'>
                            <p className='viewCompletionsPopupOptionGrade'>{Math.round(completion.GradeForBlock)}%</p>
                        </div>
                        {/* <p className='viewCompletionsPopupOptionDate'>August 1st, 10:37 PM</p> */}
                        <p className='viewCompletionsPopupOptionDate'>{this.formatDate(completion.TimeAttempted)}</p>
                    </div>
                ))}
            </div>
            <div className='viewCompletionsPopupButtonContainer'>
                <button className='viewCompletionsPopupButton secondaryButton' onClick={this.props.closePopup}>Close</button>
                {this.state.fetchOutstanding ?
                    <div className='incompleteCasePopupLoadingWrapper'>
                        <MDSpinner
                            size={30}
                            singleColor={this.props.primaryColor}
                        />
                    </div>
                :
                    <button className='viewCompletionsPopupButton primaryButton' onClick={this.confirmNewCompletion}>Confirm</button>
                }
            </div>
        </motion.div>
      </div>
    )
  }
}
