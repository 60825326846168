import { faCalendar, faLineChart, faPenToSquare, faStar, faWrench } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { AnimatePresence } from 'framer-motion/dist/framer-motion'
import React, { Component } from 'react'
import MDSpinner from 'react-md-spinner'
import LineGraph from './LineGraph/LineGraph'
import './NewGradingPage.css'
import ViewCompletionsPopup from './ViewCompletionsPopup/ViewCompletionsPopup'
import ConvertMountainTimeToLocal from '../../Functions/ConvertMountainTimeToLocal'

export default class NewGradingPage extends Component {
  constructor() {
    super()
    this.state = {
      previousAttemptList: [],
      selectedAttemptDetails: null,
      dailyPerformance: [],
      dailyPerformanceLoading: false,
      correctQuestionCount: 0,
      totalQuestionCount: 0,
      expandedQuestions: [],
      viewPreviousCompletionsPopup: false,
      previousCompletionSelected: 0,
      highestDailyScore: 20,
      showAllAnswerOptions: true,
      expandQuestionSummaries: true,
      questionSummaryTextSize: 0,
      answerOptionsExpanded: []
    }
  }

  componentDidMount() {
    this.fetchPreviousAttempts()
    this.getDailyPerformance()
  }

  getDailyPerformance = async () => {
    this.setState({dailyPerformanceLoading: true})
    fetch(`${this.props.route}/dailyperformance.webapi`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Token': this.props.userData.Token,
      },
      body: JSON.stringify({
        CustomerId: this.props.userData.CustomerId
      })
    })
    .then(response => {
      this.setState({dailyPerformanceLoading: false})
      if (response.status === 401) {
				this.props.setAuthenticationError()
				throw new Error('Authentication Error')
			} else {
				return response.json()
			}
    })
    .then((response) => {

      let maxValue = 0
      response.forEach((value) => {
        if (value.dailypercentage > maxValue)
          maxValue = value.dailypercentage
      })
      // console.log(response)

      this.setState({dailyPerformance: response, highestDailyScore: maxValue})
    })
    .catch((error) => {
      this.props.displayErrorPopupHandler('Error: Failed to load daily performance', `${error}`)
      this.setState({dailyPerformanceLoading: false})
      console.log(error)
    })
  }

  fetchPreviousAttempts = async () => {
    fetch(`${this.props.route}/getcompletedtests.webapi`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain',
        'Token': this.props.userData.Token,
      }
    })
    .then(response => {
			if (response.status === 401) {
				this.props.setAuthenticationError()
				throw new Error('Authentication Error')
			} else {
				return response.json()
			}
		})
		.then(response => {
			// console.log(response);
      this.setState({previousAttemptList: response.reverse()}, (response) => {
        let latestBlockGuid = this.state.previousAttemptList[0].guid
        this.getCompletionDetails(latestBlockGuid)
      })
		})
    .catch((error) => {
      console.log(error);
      this.props.displayErrorPopupHandler('Error: Failed to load previous attempts', `${error}`)
    })
  }

  getCompletionDetails = (blockguid) => {
    fetch(`${this.props.route}/retrievecompletedtestgrade.webapi`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Token': this.props.userData.Token,
      },
      body: JSON.stringify({
        blockguid
      })
    })
    .then(response => {
      if (response.status === 401) {
        this.props.setAuthenticationError()
        throw new Error('Authentication Error')
      } else {
        return response.json()
      }
    })
    .then(response => {
      // console.log(response)
      this.setState({selectedAttemptDetails: response, expandedQuestions: [], ...this.getExamDataDetails(response)})
    })
    .catch((error) => {
      console.log(error);
      this.props.displayErrorPopupHandler('Error: Failed to load attempt', `${error}`)
    })
  }

  getExamDataDetails = (examDetails) => {
    let totalQuestionCount = examDetails.sgolist.length
    let correctQuestionCount = 0
    let attemptDate = this.state.previousAttemptList[this.state.previousCompletionSelected].TimeAttempted

    examDetails.sgolist.forEach((sgoValue, sgoIndex) => {
      correctQuestionCount += sgoValue.GradeForQuestion
    })

    correctQuestionCount = correctQuestionCount / 100

    // console.log({totalQuestionCount, correctQuestionCount, attemptDate})

    return {totalQuestionCount, correctQuestionCount, attemptDate}
  }

  getAnswerOptionColorClass = (answerOption) => {
    if (answerOption.Correct && answerOption.UserSelected) {
      return 'feedbackCheckboxWrapperCorrect'
    } else if (answerOption.Correct && !answerOption.UserSelected) {
      return 'feedbackCheckboxWrapperOrange'
    } else if (!answerOption.Correct && answerOption.UserSelected) {
      return 'feedbackCheckboxWrapperIncorrect'
    } else {
      return 'feedbackCheckboxWrapperNeutral'
    }
  }

  getAnswerContainerColorClass = (answerOption) => {
    if (answerOption.Correct && answerOption.UserSelected) {
      return 'feedbackAnswerContainerCorrect'
    } else if (answerOption.Correct && !answerOption.UserSelected) {
      return 'feedbackAnswerContainerOrange'
    } else if (!answerOption.Correct && answerOption.UserSelected) {
      return 'feedbackAnswerContainerIncorrect'
    } else {
      return 'feedbackAnswerContainerNeutral'
    }
  }

  GetQuestionGrade = (props) => {
    if (props.GradeForQuestion === 100) {
      return (
        <div className='feedbackQuestionGradeContainer feedbackQuestionCorrect'>
          <p className='feedbackQuestionGradeText'>Question correct. Points received: <strong>1 / 1</strong></p>
        </div>
      )
    } else if (props.GradeForQuestion === 0) {
      return (
        <div className='feedbackQuestionGradeContainer feedbackQuestionIncorrect'>
          <p className='feedbackQuestionGradeText'>Question incorrect. Points received: <strong>0 / 1</strong></p>
        </div>
      )
    } else {
      return (
        <div className='feedbackQuestionGradeContainer feedbackQuestionPartiallyCorrect'>
          <p className='feedbackQuestionGradeText'>Question partially correct. Points received: <strong>{(props.GradeForQuestion / 100).toFixed(2)} / 1</strong></p>
        </div>
      )
    }
  }

  handleSelectedCompletionChange = () => {
    let latestBlockGuid = this.state.previousAttemptList[this.state.previousCompletionSelected].guid
    this.getCompletionDetails(latestBlockGuid)
  }

  formatAttemptDate = (rawDate) => {
    // format rawDate to user timezone
    // console.log(this.props.userData.TimeZone)
    let date = new Date(ConvertMountainTimeToLocal(rawDate, this.props.userData.TimeZone))
    const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    let month = MONTHS[date.getMonth()]
    let day = date.getDate()
    let year = date.getFullYear()
    let hour = date.getHours()
    let minute = date.getMinutes()
    let ampm = hour >= 12 ? 'pm' : 'am'
    hour = hour % 12
    hour = hour ? hour : 12
    minute = minute < 10 ? '0' + minute : minute
    let formattedDate = `${month} ${day}, ${year}, ${hour}:${minute} ${ampm}`
    return formattedDate
  }

  toggleQuestionSummary = (questionIndex) => {
    let expandedQuestions = [...this.state.expandedQuestions]
    if (expandedQuestions.includes(questionIndex)) {
      expandedQuestions.splice(expandedQuestions.indexOf(questionIndex), 1)
    } else {
      expandedQuestions.push(questionIndex)
    }
    this.setState({expandedQuestions})
  }

  render() {
    return (
      <div className='gradingPageWrapper'>
        <div className='gradingOverviewWrapper'>
          <div className='homepageTitleContainer'>
            <FontAwesomeIcon icon={faLineChart} className='homepageTitleIcon'/>
            <h1 className='homepageTitleText'>My Grades</h1>
          </div>
          <div className='gradingGraphWrapper'>
            <div className='gradingGraphTextContainer'>
              <h2 className='gradingGraphTextHeader'>Your Performance Summary</h2>
              <p className='gradingGraphTextBody'>A brief overview of your performance since you started using our app. These figures are calculated by taking your daily total number of correct questions divided by the total number of questions completed on that day.</p>
            </div>
            <div className='gradingGraphContainer'>
              {this.state.dailyPerformanceLoading ?
                <div className='performanceLoadingSpinnerWrapper'>
                  <MDSpinner
                    size={50}
                    singleColor={'#0B335D'}
                  />
                </div>
              :
                <LineGraph
                  data={this.state.dailyPerformance}
                  timeZone={this.props.userData.TimeZone}
                  maxValue={this.state.highestDailyScore}
                />
              }
            </div>
          </div>
        </div>
        <div className='gradingPreviousCompletionsContainer'>
          <div className='gradingPreviousCompletionsHeaderContainer'>
            <FontAwesomeIcon icon={faPenToSquare} className='homepageTitleIcon smallerIcon' />
            <h2 className='gradingPreviousCompletionsHeaderText'>Grading Feedback</h2>
            <button className='primaryButton gradingPreviousCompletionsHeaderButton' onClick={() => this.setState({viewPreviousCompletionsPopup: true})}>View Previous Completions</button>
          </div>
          <div className='gradingToolbarContainer'>
            <div className='gradingToolbarHeaderContainer'>
              <FontAwesomeIcon icon={faWrench} className='gradingToolbarHeaderIcon' />
              <h3 className='gradingToolbarHeaderText'>Tools</h3>
            </div>
            <div className='gradingToolbarBodyWrapper'>
              <div className='gradingToolbarOptionWrapper'>
                <h2 className='gradingToolbarOptionTitle'>Show All Answer Options</h2>
                <button className={`gradingToolbarToggleContainer ${this.state.showAllAnswerOptions ? 'gradingToolbarToggleContainerEnabled' : ''}`} onClick={() => this.setState({showAllAnswerOptions: !this.state.showAllAnswerOptions, answerOptionsExpanded: []})}>
                  <div className={`gradingToolbarToggleSwitch ${this.state.showAllAnswerOptions ? 'gradingToolbarToggleSwitchEnabled' : ''}`} />
                </button>
              </div>
              <div className='gradingToolbarOptionWrapper'>
                <h2 className='gradingToolbarOptionTitle'>Expand Question Summaries</h2>
                <button className={`gradingToolbarToggleContainer ${this.state.expandQuestionSummaries ? 'gradingToolbarToggleContainerEnabled' : ''}`} onClick={() => this.setState({expandQuestionSummaries: !this.state.expandQuestionSummaries, expandedQuestions: []})}>
                  <div className={`gradingToolbarToggleSwitch ${this.state.expandQuestionSummaries ? 'gradingToolbarToggleSwitchEnabled' : ''}`} />
                </button>
              </div>
              <div className='gradingToolbarOptionWrapper'>
                <h2 className='gradingToolbarOptionTitle'>Question Summary Size</h2>
                <div className='gradingToolbarTextSizeOptionsWrapper'>
                  <button className={`gradingToolbarTextSizeOption gradingToolbarTextOptionSmall ${this.state.questionSummaryTextSize === 0 ? 'gradingToolbarTextOptionSelected' : ''}`} onClick={() => this.setState({questionSummaryTextSize: 0})}>A</button>
                  <button className={`gradingToolbarTextSizeOption ${this.state.questionSummaryTextSize === 1 ? 'gradingToolbarTextOptionSelected' : ''}`} onClick={() => this.setState({questionSummaryTextSize: 1})}>A</button>
                  <button className={`gradingToolbarTextSizeOption gradingToolbarTextOptionLarge ${this.state.questionSummaryTextSize === 2 ? 'gradingToolbarTextOptionSelected' : ''}`} onClick={() => this.setState({questionSummaryTextSize: 2})}>A</button>
                </div>
              </div>
            </div>
            </div>
          <div className='gradingMetricContainer gradingScoreContainer'>
            <div className='gradingMetricIconWrapper'>
              <FontAwesomeIcon icon={faStar} className='gradingMetricIcon' />
            </div>
            <div className='gradingMetricTextWrapper'>
              <h3 className='gradingMetricTextHeader'>Your Score</h3>
              <div>
                <p className='gradingMetricTextMain'>{this.state.totalQuestionCount === 0 ? 0 : Math.round((this.state.correctQuestionCount / this.state.totalQuestionCount) * 100)}%</p>
                <p className='gradingMetricTextSub'>({Math.round(this.state.correctQuestionCount * 100) / 100}/{this.state.totalQuestionCount})</p>
              </div>
            </div>
          </div>
          <div className='gradingMetricContainer gradingDateContainer'>
            <div className='gradingMetricIconWrapper'>
              <FontAwesomeIcon icon={faCalendar} className='gradingMetricIcon' />
            </div>
            <div className='gradingMetricTextWrapper'>
              <h3 className='gradingMetricTextHeader'>Date Attempted</h3>
              <p className='gradingMetricTextMain'>{this.state.attemptDate ? this.formatAttemptDate(this.state.attemptDate) : ''}</p>
            </div>
          </div>

          {!this.state.selectedAttemptDetails &&
            <div className='gradingFeedbackLoaderWrapper'>
              <MDSpinner
                size={50}
                singleColor={'#0B335D'}
              />
            </div>
          }

          {this.state.selectedAttemptDetails?.sgolist.map((questionDetails, questionIndex) => (
            <div className='gradingFeedbackQuestionContainer' key={`${questionDetails.BlockId} - ${questionIndex}`}>
              <div className='feedbackQuestionHeaderWrapper'>
                <h3 className='feedbackQuestionNumber'>{questionIndex + 1}</h3>
                <p className='feedbackQuestionTitle'>{questionDetails.Question}</p>
              </div>
              <this.GetQuestionGrade
                GradeForQuestion={questionDetails.GradeForQuestion}
              />
              {questionDetails.sgo.map((answerOption, answerIndex) => (
                ((answerOption.Correct || answerOption.UserSelected || this.state.answerOptionsExpanded.includes(`${this.state.selectedAttemptDetails.BlockGuid} - ${questionDetails.QuestionId}`)) || this.state.showAllAnswerOptions) &&
                  <div className={`feedbackAnswerOptionContainer ${this.getAnswerContainerColorClass(answerOption)}`} key={`${answerOption.AnswerID} - ${answerIndex}`}>
                    <div className={`feedbackAnswerOptionCheckboxWrapper ${this.getAnswerOptionColorClass(answerOption)}`}>
                      <div className='feedbackAnswerOptionCheckbox'>
                        {answerOption.UserSelected &&
                          <FontAwesomeIcon icon={faCheck} className='feedbackAnswerOptionCheckboxCheck' />
                        }
                      </div>
                      {/* <input type='checkbox' checked={true} onClick={() => null} className='feedbackAnswerOptionCheckbox' /> */}
                    </div>
                    <div className='feedbackAnswerTextWrapper'>
                      <p className='feedbackAnswerText'>{answerOption.AnswerString}</p>
                      <p className='feedbackAnswerSubText'>{answerOption.EventName}</p>
                    </div>
                  </div>
              ))}
              {(!this.state.answerOptionsExpanded.includes(`${this.state.selectedAttemptDetails.BlockGuid} - ${questionDetails.QuestionId}`) && !this.state.showAllAnswerOptions) &&
                <button className='expandQuestionAnswersButton primaryButton' onClick={() => this.setState({answerOptionsExpanded: [...this.state.answerOptionsExpanded, `${this.state.selectedAttemptDetails.BlockGuid} - ${questionDetails.QuestionId}`]})}>View Other Answer Options</button>
              }
              <div className='feedbackQuestionSummaryContainer'>
                <div className='feedbackQuestionSummaryHeaderWrapper'>
                  {!this.state.expandQuestionSummaries && <button
                    className={`feedbackQuestionSummaryExpandButton ${(!this.state.expandedQuestions.includes(questionIndex) && !this.state.expandQuestionSummaries) ? '' : 'feedbackQuestionSummaryExpandButtonEnabled' }`}
                    onClick={() => this.toggleQuestionSummary(questionIndex)}
                  />}
                  <h2 className='feedbackQuestionSummaryTitle'>Question Summary</h2>
                </div>
                <div className={`feedbackQuestionSummaryTextWrapper ${(this.state.expandedQuestions.includes(questionIndex) || this.state.expandQuestionSummaries) ? '' : 'feedbackQuestionSummaryHidden'}`}>
                  <p className={`feedbackQuestionSummaryText ${this.state.questionSummaryTextSize === 1 ? 'feedbackQuestionSummaryTextMedium' : this.state.questionSummaryTextSize === 2 ? 'feedbackQuestionSummaryTextLarge' : ''}`}>{questionDetails.QuestionSummary}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <AnimatePresence exitBeforeEnter>
          {this.state.viewPreviousCompletionsPopup &&
            <ViewCompletionsPopup
              previousCompletions={this.state.previousAttemptList}
              previousCompletionSelected={this.state.previousCompletionSelected}
              closePopup={() => this.setState({viewPreviousCompletionsPopup: false})}
              primaryColor={"#0B335D"}
              confirm={() => this.handleSelectedCompletionChange()}
              updatePreviousCompletionSelected={(newCompletionIndex) => this.setState({previousCompletionSelected: newCompletionIndex})}
              timeZone={this.props.userData.TimeZone}
            />
          }
        </AnimatePresence>
      </div>
    )
  }
}
