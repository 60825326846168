import React, { useEffect, useRef, useState } from 'react';
import TestHeader from './TestHeader';
import TestFooter from './TestFooter';
import Question from './Question';
import flagIcon from '../../assets/flag-icon.svg';
import Calculator from './Calculator/Calculator';
import Labs from './Labs/Labs';
import Spinner from 'react-md-spinner'
import PracticeModeAnswerPopup from './PracticeModeAnswerPopup/PracticeModeAnswerPopup';

function Test(props) {
  const { route, userData } = props;
  const questionsListRef = useRef();
  const mainRef = useRef();
  const [fontSize, setFontSize] = useState({option: 'small', style: '1rem'});
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [questionData, setQuestionData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [currentQuestionData, setCurrentQuestionData] = useState({});
  const [currentAnswerData, setCurrentAnswerData] = useState([]);
  // const [blockguid, setBlockguid] = useState('');
  const [displayCalculator, setDisplayCalculator] = useState(false);
  const [completedQuestions, setCompletedQuestions] = useState([])
  const [displayLabs, setDisplayLabs] = useState(false)
  const [remainingTime, setRemainingTime] = useState(null)
  const [currentMarkedQuestions, setCurrentMarkedQuestions] = useState([])
  const [groupedQuestions, setGroupedQuestions] = useState([])
  const [showPausePopup, setShowPausePopup] = useState(false)
  const [showEndPopup, setShowEndPopup] = useState(false)
  const [attemptName, setAttemptName] = useState('')
  const [loadingCustomerLabel, setLoadingCustomerLabel] = useState(false)
  const [practicePopupDetails, setPracticePopupDetails] = useState(null)
  const [questionAnswersMap, setQuestionAnswersMap] = useState({})
  const [isTimerActive, setIsTimerActive] = useState(false)

  useEffect(() => {

    // Logic for whether the questions list should be displayed based off of the current window size //
    window.addEventListener('resize', () => {
      const notNull = questionsListRef.current;
      if (notNull) {
        if (window.innerWidth > 600) {
          questionsListRef.current.style.display = "block";
        } else {
          questionsListRef.current.style.display = "none";
        }
      }
    });

    initializeExamData()

    return () => {
      window.removeEventListener('resize', () => {});
    }
  }, []);

  // This is the app timer, it initializes when the exam starts and stops when the exam ends
  useEffect(() => {
    let timerInstance
    if (isTimerActive && props.timedExam) {
      timerInstance = setInterval(() => {
        setRemainingTime(remainingTime - 1)
      }, 1000)
      if (remainingTime <= 0) {
        clearInterval(timerInstance)
        endBlockHandler()
      }
    }

    return() => {
      clearInterval(timerInstance)
    }
  }, [isTimerActive, remainingTime])

  useEffect(() => {
    if (questionData && props.markedQuestions) {
      let array1 = questionData.QuestionsRandomlyGenerated
      let array2 = props.markedQuestions
      // find all elements that exist in both arrays
      let intersection = array1.filter(x => array2.includes(x));
      setCurrentMarkedQuestions(intersection)
    }
  }, [questionData, props.markedQuestions])

  const initializeExamData = () => {
    let data = props.testDetails

    let initialArray = data.QuestionsRandomlyGenerated2 ? data.QuestionsRandomlyGenerated2 : data.QuestionsForBlock
    let newQuestionArray = []
    let tempGroupedQuestions = []
    initialArray.forEach((questions, index) => {
      if (questions.length > 1) {
        tempGroupedQuestions.push(questions.map((question) => Number(question)))
      }
      questions.forEach((questionId) => {
        newQuestionArray.push(Number(questionId))
      })
    })
    data.QuestionsRandomlyGenerated = newQuestionArray

    let timeLeft = 0

    if (data.QuestionsForBlock) {
      // The exam is an incomplete start
      timeLeft = data.TimeLeft


      // Update the questionAnswersMap
      let tempQuestionAnswersMap = {}
      data.PreviousInput.forEach((input) => {
        tempQuestionAnswersMap[input.QuestionId] = input.AnswerIds
      })
      setQuestionAnswersMap(tempQuestionAnswersMap)

      // Update completed Questions
      let tempCompletedQuestions = data.PreviousInput.map((question) => question.QuestionId)
      setCompletedQuestions(tempCompletedQuestions)

      // Get the current question number by finding the first incomplete question
      let currentQuestionNumber = 0
      for(let i = 0; i < data.QuestionsRandomlyGenerated.length; i++) {
        if (!tempCompletedQuestions.includes(data.QuestionsRandomlyGenerated[i])) {
          currentQuestionNumber = i
          break
        }
      }

      setCurrentQuestionNumber(currentQuestionNumber)
      data.blockguid = data.Blockguid
    } else {
      // The exam is a new exam
      timeLeft = data.TOTALMINFORCASE * 60
    }



    setQuestionData(data)
    setGroupedQuestions(tempGroupedQuestions)
    setCurrentQuestionData(data.FirstQuestion)
    data.FirstAnswer = data.FirstAnswer.sort(() => Math.random() - 0.5)
    setCurrentAnswerData(data.FirstAnswer)
    setRemainingTime(timeLeft)
    setIsTimerActive(true)
  }

  const handleSpecificQuestionSelection = (questionId) => {
    if (isQuestionIdGrouped(questionId)) {
      questionId = getFirstUnansweredGroupQuestionId(questionId)
    }

    fetchQuestionById(questionId)
  }

  const getNextUnansweredQuestionId = () => {
    let currentQuestionId = currentQuestionData.QUESTIONID
    // We are calling this on question submission. We need to find the next unanswered question
    const questionIdList = questionData.QuestionsRandomlyGenerated

    // First we need to find the index of the current question
    let currentQuestionIndex = questionIdList.indexOf(currentQuestionId)

    // Increment the current question index
    currentQuestionIndex++

    // Check if we're out of bounds. If so, go back to the first question
    if (currentQuestionIndex >= questionIdList.length) {
      currentQuestionIndex = 0
    }

    // Check if our new index is already completed. If it is, we need to find the next unanswered question
    while(completedQuestions.includes(questionIdList[currentQuestionIndex])) {
      currentQuestionIndex++
      if (currentQuestionIndex >= questionIdList.length) {
        currentQuestionIndex = 0
      }

      // If we are back at our initial question, we know we have completed all questions.
      // We flag this by returning -1
      if (currentQuestionIndex === questionIdList.indexOf(currentQuestionId)) {
        return -1
      }
    }

    return questionIdList[currentQuestionIndex]
  }

  const getNextQuestion = () => {
    const questionList = questionData.QuestionsRandomlyGenerated
    let questionNumber = currentQuestionNumber;
    questionNumber++

    // If the question number is greater than the total number of questions, then we start back at the beginning
    if (questionNumber > questionList.length - 1) {
      questionNumber = 0
    }
    let questionId = questionList[questionNumber]

    // We need to check if our target questionId is grouped. If it is, update the target id accordingly
    if (isQuestionIdGrouped(questionId)) {
      questionId = getFirstUnansweredGroupQuestionId(questionId)

      // If the new question id is the same as the current question id, then we need to find the first next question
      // outside of the group
      if (questionId === questionList[currentQuestionNumber]) {
        // Find the current question group
        let questionGroup
        groupedQuestions.forEach((group) => {
          if (group.includes(questionId)) {
            questionGroup = group
          }
        })

        // Get the last questionId in the group
        let lastQuestionId = questionGroup[questionGroup.length - 1]

        // Get the index of the last questionId in the group
        let questionIndex = questionList.indexOf(lastQuestionId)

        // Increment the index
        questionIndex++

        // If we are out of bounds, go back to the beginning
        if (questionIndex >= questionList.length) {
          questionIndex = 0
        }

        // Set the question id to the new question id
        questionId = questionList[questionIndex]
      }
    }

    fetchQuestionById(questionId)
  }

  const getPrevQuestion = () => {
    let questionNumber = currentQuestionNumber;
    questionNumber--

    // If the question number is less than 0, then we start back at the end
    if (questionNumber < 0) {
      questionNumber = questionData.QuestionsRandomlyGenerated.length - 1
    }
    let questionId = questionData.QuestionsRandomlyGenerated[questionNumber]

    // We need to check if our target questionId is grouped. If it is, update the target id accordingly
    if (isQuestionIdGrouped(questionId)) {
      questionId = getFirstUnansweredGroupQuestionId(questionId)
    }

    fetchQuestionById(questionId)
  }

  const isQuestionIdGrouped = (questionId) => {
    // Determine if the questionId is grouped and return a boolean indicating the result
    let isGrouped = false
    groupedQuestions.forEach((group) => {
      if (group.includes(questionId)) {
        isGrouped = true
      }
    })
    return isGrouped
  }

  const getFirstUnansweredGroupQuestionId = (questionId) => {
    // Our target question id is grouped.
    // If the target question is already completed, go to the target question.
    // Else, go to the first unanswered question in the group

    if (completedQuestions.includes(questionId)) {
      // Question is completed, return the target question id
      return questionId
    }

    // From here, we know our target question is incomplete. We need to find the first unanswered question in the group.
    // First, we find what group we are in and store it in targetGroup
    let targetGroup = []
    groupedQuestions.forEach((group) => {
      if (group.includes(questionId)) {
        targetGroup = group
      }
    })

    // Next, we iterate through the group to find the first unanswered question
    for(let i = 0; i < targetGroup.length; i++) {
      if (!completedQuestions.includes(targetGroup[i])) {
        return targetGroup[i]
      }
    }

    // This code shouldn't be reached. If it is, we throw an error which indicates there is a bug in the code
    throw new Error('Error getting first unanswered question in group')
  }

  // This function will be called only by the submit button. Record Value will only be true here
  const submitQuestion = async () => {
    if (props.practiceMode) {
      fetchPracticeModeData()
    } else {
      let nextQuestionId = getNextUnansweredQuestionId()

      fetchQuestionById(nextQuestionId, true)
    }
  }

  const fetchPracticeModeData = async () => {
    const requestBody = {
      customerId: userData.CustomerId,
      timeLeftInSec: props.timedExam ? remainingTime : 0,
      blockId: currentQuestionData.BLOCKID,
      blockguid: questionData.blockguid,
      completeSubmission: false,
      isTimed: props.timedExam,
      questionId: currentQuestionData.QUESTIONID,
      answerIds: selectedAnswers.map((answer) => Number(answer)),
    }

    try {
      setIsLoading(true)
      const response = await fetch(`${route}/selectgrading.webapi`, {
        method: 'POST',
        headers: {
          'Token': userData.Token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      })

      if (response.status === 401) {
        return props.setAuthenticationError()
      }

      let data = await response.json()
      setPracticePopupDetails(data)
    }
    catch (error) {
      console.log(error)
    }
    finally {
      setIsLoading(false)
    }
  }

  const fetchQuestionById = async (nextQuestionId, RecordValue = false) => {

    // If our next question is the same as our current question, we don't need to fetch anything
    if (nextQuestionId === currentQuestionData.QUESTIONID && !RecordValue)
      return

    // Need to determine if we are finished with the exam
    let completeSubmission = false
    if (isSelectedQuestionLastQuestion() && RecordValue)
      completeSubmission = true

    let requestBody = {
      customerId: userData.CustomerId,
      timeLeftInSec: remainingTime,
      blockId: currentQuestionData.BLOCKID,
      blockguid: questionData.blockguid,
      completeSubmission,
      isTimed: props.timedExam,
      // questionId: questionData.QuestionsRandomlyGenerated[currentQuestionNumber],
      questionId: currentQuestionData.QUESTIONID,
      answerIds: selectedAnswers,
      NextQuestionId: nextQuestionId === -1 ? currentQuestionData.QUESTIONID : nextQuestionId,
      RecordValue: completedQuestions.includes(currentQuestionData.QUESTIONID) ? false : RecordValue,
    }

    setIsLoading(true);
    fetch(`${route}/moveon.webapi`, {
      method: 'POST',
      headers: {
        'Token': userData.Token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    })
    .then((response) => {
      if (response.status === 401) {
        return props.setAuthenticationError()
      }
      return response.json()
    })
    .then((data) => {

      // This whole section still needs to be cleaned up

      if (RecordValue) {
        let questionAnswers = {...questionAnswersMap}
        questionAnswers[currentQuestionData.QUESTIONID] = selectedAnswers
        setQuestionAnswersMap(questionAnswers)
        if (!completedQuestions.includes(currentQuestionData.QUESTIONID)) {
          setCompletedQuestions([...completedQuestions, currentQuestionData.QUESTIONID])
        }
        if (requestBody.completeSubmission) {
          // Exam is completed
          return props.displayExamGrade(data)
        }
      } else {
        // Check if there is an answer for the question
        if (questionAnswersMap[nextQuestionId] !== undefined) {
          setSelectedAnswers(questionAnswersMap[nextQuestionId].map((answer) => answer.toString()))
        }
      }
      setCurrentQuestionData(data.NextQuestion);
      data.NextAnswer = data.NextAnswer.sort(() => Math.random() - 0.5)
      setCurrentAnswerData(data.NextAnswer);
      // Find index of QUESTIONID in QuestionsRandomlyGenerated array
      let questionNumber = questionData.QuestionsRandomlyGenerated.indexOf(data.NextQuestion.QUESTIONID);
      setCurrentQuestionNumber(questionNumber);
      setIsLoading(false);
      setPracticePopupDetails(null)
    })
    .catch((error) => {
      setIsLoading(false);
      console.log(error);
    })
  }

  // Handle the answer change //
  function handleAnswerChange(e) {
    const answerId = e.target.value;
    const inputType = e.target.type;
    const isChecked = selectedAnswers.includes(answerId);

    if (inputType === "radio") {
      setSelectedAnswers([answerId]);
    }

    if (inputType === "checkbox") {
      if (isChecked) {
        setSelectedAnswers((prev) => prev.filter(id => id !== answerId));
      } else {
        setSelectedAnswers((prev) => [...prev, answerId]);
      }
    }
  }

  // Text zoom functionality //
  function handleTextZoom(size) {
    switch(size) {
      case 'small':
        setFontSize({option: 'small', style: "1rem"});
        break;
      case 'medium':
        setFontSize({option: 'medium', style: '1.2rem'});
        break;
      case 'large':
        setFontSize({option: 'large', style: '1.4rem'});
        break;
      default:
        setFontSize({option: 'small', style: "1rem"});
        break;
    }
  }

  // Reverse color functionality //
  function toggleColorMode() {
    if (!isDarkMode) {
      // Dark Mode
      document.documentElement.style.setProperty('--background-color', '#000');
      document.documentElement.style.setProperty('--labs-text-color', '#fff');
      document.documentElement.style.setProperty('--labs-color', '#33353d');
      document.documentElement.style.setProperty('--input-color', '#33353d');
    } else {
      // Light Mode
      document.documentElement.style.setProperty('--background-color', 'linear-gradient(#fff, #d7ddec)');
      document.documentElement.style.setProperty('--labs-text-color', '#000');
      document.documentElement.style.setProperty('--labs-color', '#D7DCED');
      document.documentElement.style.setProperty('--input-color', '#FFFFFF');
    }
    setIsDarkMode(prev => !prev);
  }

  const isQuestionMarked = (questionId) => {
    if (questionId === undefined) {
      questionId = currentQuestionData.QUESTIONID
    }
    if (currentMarkedQuestions.includes(questionId))
      return true
    
    return false
  }

  const markQuestion = () => {
    let markedArray = [...props.markedQuestions];

    // Get QuestionId from currentQuestionData
    const questionId = currentQuestionData.QUESTIONID;

    if (isQuestionMarked(questionId)) {
      const questionIndex = markedArray.findIndex(question => question === questionId)
      // Remove question from markedArray
      markedArray.splice(questionIndex, 1)
    } else {
      markedArray.push(questionId)
    }
    props.setMarkedQuestions(markedArray);
  }

  const handleMarkQuestion = async () => {
    let questionId = currentQuestionData.QUESTIONID;
    const response = await fetch(`${route}/addremovemarkedquestion.webapi`, {
      method: 'POST',
      headers: {
        'Token': userData.Token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        QuestionId: currentQuestionData.QUESTIONID,
        CustomerId: userData.CustomerId,
        RemoveQuestion: isQuestionMarked(questionId) ? true : false
      })
    });

    if (response.ok) {
      markQuestion();
    }

    if (response.status === 401) {
      props.setAuthenticationError()
    }

    // console.log(await response.text())
  }

  const formatSeconds = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const leftoverSeconds = seconds % 60;
    return `${minutes}:${leftoverSeconds < 10 ? '0' : ''}${leftoverSeconds}`;
  }

  const endBlockHandler = async () => {
    try {
      const response = await fetch(`${route}/manuallyendblock.webapi`, {
        method: 'POST',
        headers: {
          'Token': userData.Token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          blockguid: questionData.blockguid
        })
      })
  
      if (response.status === 401) {
        return props.setAuthenticationError()
      }

      if (response.status === 200) {
        return props.displayExamGrade()
      }

      window.alert('Failed to End Block. Please try again or contact us if this issue persists.')
    } catch(error) {
      console.log(error)
      window.alert('Failed to End Block. Please try again or contact us if this issue persists.')
    }
  }

  const pauseBlockHandler = async () => {
    if (attemptName !== '') {
      try {

        setLoadingCustomerLabel(true)
        const response = await fetch(`${route}/incompletecustomerlabel.webapi`, {
          method: 'POST',
          headers: {
            'Token': userData.Token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            guid: questionData.blockguid,
            CustomerLabel: attemptName
          })
        })
        setLoadingCustomerLabel(false)

        if (!response.ok) {
          throw new Error('Failed to add customer label')
        } else if (response.status === 401) {
          return props.setAuthenticationError()
        }
      } catch(error) {
        setLoadingCustomerLabel(false)
        return console.log(error)
      }
    }
    props.pauseBlockHandler()
  }

  const isSelectedQuestionLastQuestion = () => {
    if (!questionData)
      return false

    // Need to check if the completed questions array is 1 less than the total questions array
    if (completedQuestions.length >= (questionData.QuestionsRandomlyGenerated.length - 1)) {
      // If it is, check if the currently selected question is the outstanding question
      if (!completedQuestions.includes(currentQuestionData.QUESTIONID)) {
        return true
      }
    }
    return false
  }

  // This code is responsible for building the question list
  let questionsList = []
  let groupedBefore = false
  let lastGrouped = false
  if (questionData) {
    for (let i = 0; i < questionData.QuestionsRandomlyGenerated.length; i++) {
      const questionNumber = i;
      let questionId = questionData.QuestionsRandomlyGenerated[questionNumber]
      let grouped = false
      let isLastGroupedQuestion = false
      // Check if questionId exists in groupedQuestions
      groupedQuestions.forEach((questionGroup) => {
        if (questionGroup.includes(questionId)) {
          grouped = true
          if (questionGroup[questionGroup.length - 1] === questionId) {
            isLastGroupedQuestion = true
          }
        }
      })

      let className = ['questionListItem']

      if (questionNumber === currentQuestionNumber)
        className.push('current')
      if (completedQuestions.includes(questionId))
        className.push('completedListItem')
      if (grouped) {
        if (groupedBefore) {
          if (isLastGroupedQuestion) {
            className.push('lastGroupedQuestion')
            lastGrouped = true
          } else {
            className.push('middleGroupedQuestion')
          }
        } else {
          className.push('firstGroupedQuestion')
        }
      }

      className = className.join(' ')

      questionsList.push(
        <li
          key={questionNumber}
          onClick={() => handleSpecificQuestionSelection(questionId)}
          className={className}
        >
          <p>{questionNumber + 1}</p>{isQuestionMarked(questionId) && <img className='listMarkedFlag' src={flagIcon} alt="flag icon" />}
        </li>
      )

      if (grouped && !groupedBefore) {
        groupedBefore = true
      } else if (!grouped && groupedBefore) {
        groupedBefore = false
      } else if (grouped && groupedBefore && lastGrouped) {
        grouped = false
        groupedBefore = false
        lastGrouped = false
      }
    }
  }

  return (
    <div id="test">
      <aside ref={questionsListRef} className={isDarkMode ? 'questions-list bg-dark text-white dark-questions-list' : 'questions-list'}>
        <ul>
          {questionsList}
        </ul>
      </aside>
      <div className="main" ref={mainRef}>
        <TestHeader
          currentItem={currentQuestionNumber} 
          totalQuestions={questionsList.length}
          currentBlock={1}
          totalBlocks={1}
          questionsListRef={questionsListRef}
          mainRef={mainRef}
          fontSize={fontSize}
          handleTextZoom={handleTextZoom}
          toggleColorMode={toggleColorMode}
          isDarkMode={isDarkMode}
          markQuestion={handleMarkQuestion}
          isQuestionMarked={isQuestionMarked()}
          setDisplayCalculator={() => setDisplayCalculator(!displayCalculator)}
          setDisplayLabs={() => setDisplayLabs(!displayLabs)}
          getPreviousQuestion={() => getPrevQuestion()}
          getNextQuestion={() => getNextQuestion()}
        />
        <Question
          fontSize={fontSize}
          isDarkMode={isDarkMode}
          questionData={questionData}
          handleAnswerChange={handleAnswerChange}
          selectedAnswers={selectedAnswers}
          nextQuestion={submitQuestion}
          isLoading={isLoading}
          currentAnswerData={currentAnswerData}
          currentQuestionData={currentQuestionData}
          currentQuestionNumber={currentQuestionNumber}
          isSelectedQuestionLastQuestion={isSelectedQuestionLastQuestion()}
          numberOfQuestions={questionData?.QuestionsRandomlyGenerated.length}
          displayCalculator={displayCalculator}
          displayLabs={displayLabs}
          isCurrentQuestionCompleted={completedQuestions.includes(currentQuestionData.QUESTIONID)}
          route={route}
        />
      
        <TestFooter 
          // Timed functionality still needs to be written //
          blockTimeRemaining={props.timedExam ? formatSeconds(remainingTime) : 'Untimed'}
          dayTimeRemaining={props.timedExam ? formatSeconds(remainingTime) : 'Untimed'}
          isDarkMode={isDarkMode}
          pauseBlock={() => setShowPausePopup(true)}
          endBlock={() => setShowEndPopup(true)}
        />
      </div>
      {/* Mobile Labs */}
      {displayLabs &&
        <div className='mobileLabsWrapper'>
          <div className='mobilePopupBackdrop' />
          <div className='mobilePopupContainer' style={{height: '80%'}}>
            <div className='mobilePopupHeaderContainer'>
              <h2 className='mobilePopupHeaderText'>Lab Values</h2>
              <button className='mobilePopupCloseButton' onClick={() => setDisplayLabs(false)}/>
            </div>
            <div className='mobilePopupContentContainer'>
              <Labs />  
            </div>
          </div>
        </div>
      }

      {/* Mobile Calculator */}
      {displayCalculator &&
        <div className='mobileLabsWrapper'>
          <div className='mobilePopupBackdrop' />
          <div className='mobilePopupContainer'>
            <div className='mobilePopupHeaderContainer'>
              <h2 className='mobilePopupHeaderText'>Calculator</h2>
              <button className='mobilePopupCloseButton' onClick={() => setDisplayCalculator(false)}/>
            </div>
            <div className='mobilePopupContentContainer'>
              <Calculator />  
            </div>
          </div>
        </div>
      }

      {/* Pause Popup */}
      {showPausePopup &&
        <div className='testPopupWrapper'>
          <div className='testPopupBackdrop' />
          <div className='testPopupContainer'>
            <div className='testPopupHeaderContainer'>
              <h2 className='testPopupHeaderText'>Pause Block?</h2>
              <button className='testPopupCloseButton' onClick={() => setShowPausePopup(false)}/>
            </div>
            <div className='testPopupBodyContainer'>
              <p className='testPopupBodyText'>Are you sure you want to pause your test? Your answers will be saved and you can come back later to complete this test. If you'd like to name this attempt, enter a name below. Otherwise, the date and time attempted will be displayed in your previous attempts.</p>
              <label className='testPopupInputLabel'>Attempt Name</label>
              <input className='testPopupInput' value={attemptName} onChange={(e) => setAttemptName(e.target.value)}/>
            </div>
            <div className='testPopupFooterContainer'>
              {/* <button className='testPopupFooterButton testPopupSecondaryButton' onClick={() => setShowPausePopup(false)}>Cancel</button> */}
              {loadingCustomerLabel ?
                <div className='testPopupFooterLoaderWrapper'>
                  <Spinner size={30} singleColor={'#ffffff'} />
                </div>
              :
                <button className='testPopupFooterButton testPopupPrimaryButton' onClick={() => pauseBlockHandler()}>Confirm</button>
              }
            </div>
          </div>
        </div>
      }

      {/* End Popup */}
      {showEndPopup &&
        <div className='testPopupWrapper'>
          <div className='testPopupBackdrop' />
          <div className='testPopupContainer'>
            <div className='testPopupHeaderContainer'>
              <h2 className='testPopupHeaderText'>End Block?</h2>
              <button className='testPopupCloseButton' onClick={() => setShowEndPopup(false)}/>
            </div>
            <div className='testPopupBodyContainer'>
              <p className='testPopupBodyText'>Are you sure you want to submit your answers for this test? If you have left any answers blank, you will receive a zero for that question. If you would like to save this exam to complete at a later date, use the Pause Block feature.</p>
            </div>
            <div className='testPopupFooterContainer'>
              {/* <button className='testPopupFooterButton testPopupSecondaryButton' onClick={() => setShowEndPopup(false)}>Cancel</button> */}
              <button className='testPopupFooterButton testPopupPrimaryButton' onClick={() => endBlockHandler()}>Confirm</button>
            </div>
          </div>
        </div>
      }

      {practicePopupDetails !== null &&
        <PracticeModeAnswerPopup
          practicePopupDetails={practicePopupDetails}
          currentQuestionNumber={currentQuestionNumber + 1}
          TotalQuestions={questionsList.length}
          isLoading={isLoading}
          fetchNextQuestion={fetchQuestionById}
          getNextUnansweredQuestionId={getNextUnansweredQuestionId}
        />
      }
    </div>
  )
}

export default Test;