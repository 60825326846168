import React from 'react'
import './PracticeModeAnswerPopup.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import MDSpinner from 'react-md-spinner'

const GetQuestionGrade = (props) => {
    if (props.GradeForQuestion === 100) {
        return (
            <div className='feedbackQuestionGradeContainer feedbackQuestionCorrect'>
                <p className='feedbackQuestionGradeText'>Question correct. Points received: <strong>1 / 1</strong></p>
            </div>
        )
    } else if (props.GradeForQuestion === 0) {
        return (
            <div className='feedbackQuestionGradeContainer feedbackQuestionIncorrect'>
                <p className='feedbackQuestionGradeText'>Question incorrect. Points received: <strong>0 / 1</strong></p>
            </div>
        )
    } else {
        return (
            <div className='feedbackQuestionGradeContainer feedbackQuestionPartiallyCorrect'>
                <p className='feedbackQuestionGradeText'>Question partially correct. Points received: <strong>{(props.GradeForQuestion / 100).toFixed(2)} / 1</strong></p>
            </div>
        )
    }
}

const getAnswerOptionColorClass = (answerOption) => {
    if (answerOption.Correct && answerOption.UserSelected) {
        return 'feedbackCheckboxWrapperCorrect'
    } else if (answerOption.Correct && !answerOption.UserSelected) {
        return 'feedbackCheckboxWrapperOrange'
    } else if (!answerOption.Correct && answerOption.UserSelected) {
        return 'feedbackCheckboxWrapperIncorrect'
    } else {
        return 'feedbackCheckboxWrapperNeutral'
    }
}

export default function PracticeModeAnswerPopup(props) {
  return (
    <div className='practiceModePopupPageContainer'>
        <div className='practiceModePopupBackdrop' />
        <div className='practiceModePopupWrapper'>
            <div className='practiceModeAnswerPopupHeaderContainer'>
                <p className='practiceModeAnswerPopupHeaderText'>Question {props.currentQuestionNumber} of {props.TotalQuestions}</p>
            </div>
            <div className='practiceModeAnswerPopupBodyContainer'>
                <div className='gradingFeedbackQuestionContainer' style={{backgroundColor: 'white'}}>
                    <div className='feedbackQuestionHeaderWrapper'>
                        <h3 className='feedbackQuestionNumber'>{props.currentQuestionNumber}</h3>
                        <p className='feedbackQuestionTitle'>{props.practicePopupDetails.Question}</p>
                    </div>
                    {/* <div className='feedbackQuestionSummaryContainer'>
                        <h2 className='feedbackQuestionSummaryTitle'>Question Summary</h2>
                        <p className='feedbackQuestionSummaryText'>{props.practicePopupDetails.QuestionSummary}</p>
                    </div> */}
                    <div className='feedbackQuestionSummaryContainer'>
                        <div className='feedbackQuestionSummaryHeaderWrapper'>
                            <h2 className='feedbackQuestionSummaryTitle'>Question Summary</h2>
                        </div>
                            <div className='feedbackQuestionSummaryTextWrapper'>
                            <p className='feedbackQuestionSummaryText feedbackQuestionSummaryTextMedium'>{props.practicePopupDetails.QuestionSummary}</p>
                        </div>
                    </div>
                    <GetQuestionGrade
                        GradeForQuestion={props.practicePopupDetails.GradeForQuestion}
                    />
                    {props.practicePopupDetails.sgo.map((answerOption, answerIndex) => (
                        <div className='feedbackAnswerOptionContainer' key={`${answerOption.AnswerID} - ${answerIndex}`}>
                            <div className={`feedbackAnswerOptionCheckboxWrapper ${getAnswerOptionColorClass(answerOption)}`}>
                                <div className='feedbackAnswerOptionCheckbox'>
                                {answerOption.UserSelected &&
                                    <FontAwesomeIcon icon={faCheck} className='feedbackAnswerOptionCheckboxCheck' />
                                }
                                </div>
                            </div>
                            <div className='feedbackAnswerTextWrapper'>
                                <p className='feedbackAnswerText'>{answerOption.AnswerString}</p>
                                <p className='feedbackAnswerSubText'>{answerOption.EventName}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='testPopupFooterContainer' style={{gridTemplateColumns: '1fr'}}>
                {props.isLoading ?
                    <div className='testPopupFooterLoaderWrapper'>
                        <MDSpinner size={30} singleColor={'#ffffff'} />
                    </div>
                :
                    <button className='testPopupFooterButton testPopupPrimaryButton' onClick={() => props.fetchNextQuestion(props.getNextUnansweredQuestionId(), true)}>Continue</button>
                }
            </div>
        </div>
    </div>
  )
}
