import React from "react";
import "./LineGraph.css";

import { Line } from "react-chartjs-2";

export default function LineGraph(props) {
    const data = {
        labels: props.data,
        datasets: [
            {
                data: props.data.map((value) => isNaN(value.dailypercentage) ? 0 : Math.round(value.dailypercentage)),
                fill: true,
                backgroundColor: "#AABFDB80",
                borderColor: "#0B335D",
                tension: 0.4,
                borderCapStyle: 'round'
            }
        ]
    }

    const chartAreaBorder = {
        id: 'chartAreaBorder',
        afterDatasetsDraw: (chart, args, options) => {
            const { ctx , chartArea: { top, left, width, height } } = chart
            ctx.save()
            ctx.globalCompositeOperation = 'destination-over'
            ctx.fillStyle = '#F5F8FC'
            ctx.strokeStyle = '#0B335D'
            ctx.lineWidth = 2
            ctx.strokeRect(left, top, width, height)
            ctx.fillRect(left, top, width, height)
            ctx.restore()
        }
    }

    return (
        <div className="LineGraphContainer">
            <Line
                data={data}
                plugins={[chartAreaBorder]}
                // height={'100%'}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    return `${context.raw}%`
                                },
                                title: function(context) {
                                    // let date = new Date(props.data[context[0].dataIndex].day)
                                    // let day = date.getDate()
                                    // let month = date.getMonth() + 1
                                    // let year = `${date.getFullYear()}`
                                    // return `${month}/${day}/${year.substring(2)}`
                                    return data.labels[context[0].dataIndex].day
                                }
                            }
                        }
                    },
                    layout: {
                        padding: {
                            top: 20,
                            left: 10,
                            right: 25,
                            bottom: 0
                        }
                    },
                    scales: {
                        y: {
                            ticks: {
                                callback: function(value, index, values) {
                                    return Math.round(value) + '%'
                                }
                            },
                            // max: 100,
                            // min: 0,
                            grid: {
                                display: true
                            },
                            title: {
                                text: "Avg Daily Performance",
                                display: true
                            },
                            borderColor: '#0B335D',
                            min: 0,
                            max: props.maxValue
                        },
                        x: {
                            grid: {
                                display: false
                            },
                            title: {
                                text: "Date",
                                display: true
                            },
                            ticks: {
                                maxTicksLimit: 6,
                                callback: function(value, index, values) {
                                    // let date = new Date(data.labels[index].day)
                                    // let day = date.getDate()
                                    // let month = date.getMonth() + 1
                                    // let year = `${date.getFullYear()}`
                                    // return `${month}/${day}/${year.substring(2)}`
                                    return data.labels[index].day
                                }
                            }
                        }
                    }
                }}
            />
        </div>
    );
}
