import React from 'react'
import './ErrorPopup.css'
import { motion } from 'framer-motion/dist/framer-motion'

export default function ErrorPopup(props) {
  return (
    <motion.div className='errorPopupContainer'>
        <button className='errorPopupCloseButton' onClick={props.closeErrorPopup}/>
        <div className='errorPopupTextWrapper'>
            <h2 className='errorPopupTitle'>{props.errorPopupTitle}</h2>
            <p className='errorPopupText'>{props.errorPopupText}</p>
        </div>
    </motion.div>
  )
}
