import React, { useState } from 'react'
import flagIcon from '../../assets/flag-icon.svg'
import triangleIcon from '../../assets/triangle-icon.svg'
import labIcon from '../../assets/lab-icon.svg'
import calculatorIcon from '../../assets/calculator-icon.png'
import reverseColorIcon from '../../assets/reverse-color-icon.png'

function TestHeader(props) {
  const { questionsListRef, mainRef } = props
  const [isToolDropdownOpen, setIsToolDropdownOpen] = useState(false)

  // Toggle whether the questions list is shown or not //
  function toggleQuestionsList() {
    const isShown = questionsListRef.current.style.display === 'block'

    if (!isShown) {
      questionsListRef.current.style.display = 'block'
      if (window.innerWidth <= 600) {
        mainRef.current.addEventListener('click', toggleQuestionsList)
      }
    } else {
      questionsListRef.current.style.display = 'none'
      if (window.innerWidth <= 600) {
        mainRef.current.removeEventListener('click', toggleQuestionsList)
      }
    }
  }

  // Reverse Color //
  function toggleColorMode() {
    toggleToolDropdown()
    props.toggleColorMode()
  }

  // Tool dropdown handler //
  function toggleToolDropdown() {
    setIsToolDropdownOpen(prev => !prev)
  }

  const toggleLabValues = () => {
    toggleToolDropdown()
    props.setDisplayLabs()
  }

  const toggleCalculator = () => {
    toggleToolDropdown()
    props.setDisplayCalculator()
  }

  return (
    <header className={`test-header ${props.isDarkMode ? "dark-header" : ''}`}>
      <button 
        className={props.isDarkMode ? "bg-dark text-white" : ''}
        onClick={toggleQuestionsList} 
        id="open-questions-btn"
      >
        &gt;
      </button>
      <div className='item-info-container'>
        <div className="item-info">
          <p>Item: {props.currentItem + 1} of {props.totalQuestions}</p>
          <p>Block: {props.currentBlock} of {props.totalBlocks}</p>
        </div>
        <div className="flag-container">
          <input checked={props.isQuestionMarked} onChange={() => props.markQuestion()} type="checkbox" name="flag" id="flag" />
          <label htmlFor="flag">
            <img src={flagIcon} alt="flag icon" />
            <span>Mark</span>
          </label>
        </div>
      </div>
      <div className="nav-buttons">
        <button className="previous-button" onClick={() => props.getPreviousQuestion()}>
          <img src={triangleIcon} alt="previous icon" />
          <p>Previous</p>
        </button>
        <button className="next-button" onClick={() => props.getNextQuestion()}>
          <img src={triangleIcon} alt="next icon" />
          <p>Next</p>
        </button>
      </div>
      <div className="tools" id="desktop-tools">
        <button className='tools-button' onClick={props.setDisplayLabs}>
          <img src={labIcon} alt="Beaker icon" className='tool-image'/>
          <p>Lab Values</p>
        </button>
        <button className='tools-button' onClick={props.setDisplayCalculator}>
          <img src={calculatorIcon} alt="Calculator icon" className="tool-image"/>
          <p>Calculator</p>
        </button>
        <button className='tools-button' onClick={props.toggleColorMode}>
            <img src={reverseColorIcon} alt="Reverse color icon" className="tool-image"/>
            <p>Reverse Color</p>
        </button>
        <div>
          <div className="zoom-options">
            <label 
              onClick={() => props.handleTextZoom('small')}
              htmlFor="small">
              <input
                checked={props.fontSize.option === 'small'}
                onChange={() => props.handleTextZoom('small')}
                type="radio" 
                value="small" 
                name="text-zoom-desktop" 
                id="small"
              />
              <span>A</span>
            </label>
            <label 
              onClick={() => props.handleTextZoom('medium')}
              htmlFor="medium" >
              <input
                checked={props.fontSize.option === 'medium'}
                onChange={() => props.handleTextZoom('medium')}
                type="radio" 
                value="medium" 
                name="text-zoom-desktop" 
                id="medium"
              />
              <span>A</span>
            </label>
            <label 
              onClick={() => props.handleTextZoom('large')}
              htmlFor="large">
              <input
                checked={props.fontSize.option === 'large'}
                onChange={() => props.handleTextZoom('large')}
                type="radio" 
                value="large" 
                name="text-zoom-desktop" 
                id="large"
              />
              <span>A</span>
            </label>
          </div>
          <p>Text Zoom</p>
        </div>
      </div>
      <div className="tools" id="mobile-tools">
        <button
          onClick={toggleToolDropdown}
          className={props.isDarkMode ? "btn btn-dark dropdown-toggle bg-dark" : "btn btn-light dropdown-toggle"} type="button" 
        >
          Tools
        </button>
        { isToolDropdownOpen && <ul className="dropdown-menu" style={{display: 'block'}}>
          <li>
            <button onClick={toggleLabValues} className="dropdown-item" type="button">
              <img src={labIcon} alt="Beaker icon" className='tool-image' />
              <span>Lab Values</span>
            </button>
          </li>
          <li>
            <button onClick={toggleCalculator} className="dropdown-item" type="button">
              <img src={calculatorIcon} alt="Calculator icon" className='tool-image' />
              <span>Calculator</span>
            </button>
          </li>
          <li>
            <button onClick={toggleColorMode} className="dropdown-item" type="button">
              <img src={reverseColorIcon} alt="Reverse color icon" className='tool-image' />
              <span>Reverse Color</span>
            </button>
          </li>
          <li>
            <span className="zoom-options">
              <label 
                onClick={() => props.handleTextZoom('small')}
                htmlFor="small">
                <input 
                  checked={props.fontSize.option === 'small'} 
                  onChange={() => props.handleTextZoom('small')}
                  type="radio" value="small" 
                  name="text-zoom" 
                  id="small" 
                />
                <span>A</span>
              </label>
              <label 
                onClick={() => props.handleTextZoom('medium')}
                htmlFor="medium">
                <input
                  checked={props.fontSize.option === 'medium'}
                  onChange={() => props.handleTextZoom('medium')}
                  type="radio" 
                  value="medium" 
                  name="text-zoom" 
                  id="medium" 
                />
                <span>A</span>
              </label>
              <label 
                onClick={() => props.handleTextZoom('large')}
                htmlFor="large">
                <input
                  checked={props.fontSize.option === 'large'}
                  onChange={() => props.handleTextZoom('large')}
                  type="radio"
                  value="large"
                  name="text-zoom"
                  id="large" 
                />
                <span>A</span>
              </label>
            </span>
            <span>Text Zoom</span>
          </li>
        </ul> }
      </div>
    </header>
  )
}

export default TestHeader;